<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { computed, nextTick, onMounted, ref, useSlots, watch } from 'vue';
import { tooltip as tool, ToolTipInstance } from '@/util/tooltip';
import { twMerge } from 'tailwind-merge';

export type ButtonType = 'default' | 'primary' | 'warning' | 'pending' | 'success';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'block';

export type ButtonProps = {
  noStyle?: boolean;
  type?: ButtonType;
  size?: Size;
  loading?: boolean;
  stopClick?: boolean;
  disabled?: boolean;
  disabledToolTipText?: string;
  toolTipText?: string;
  title?: string;
  hoverTitle?: string;
  hoverIcon?: string;
  icon?: string;
  postIcon?: string;
  emphasized?: boolean;
  onlyIconEmphasized?: boolean;
  wrapperClass?: string;
  isBlock?: boolean;
  pingIndicator?: boolean;
  dotIndicator?: boolean;
  tabindex?: number | null;
};

const {
  noStyle = false,
  type = 'default',
  size = 'md',
  loading = false,
  stopClick = false,
  disabled = false,
  disabledToolTipText = undefined,
  toolTipText = undefined,
  title = undefined,
  hoverTitle = null,
  icon = undefined,
  postIcon = undefined,
  emphasized = false,
  onlyIconEmphasized = false,
  wrapperClass = undefined,
  isBlock = false,
  pingIndicator = false,
  dotIndicator = false,
  tabindex = null,
} = defineProps<ButtonProps>();

const emit = defineEmits<{
  (e: 'click', arg: MouseEvent): void;
}>();

const slot = useSlots();

const button = cva(
  'font-mediumbold border transition transform enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2  inline-flex text-center items-center justify-center tracking-[0.2px]  whitespace-nowrap ',
  {
    variants: {
      type: {
        'default-off': `rounded enabled:focus-visible:outline-highlight text  cursor-pointer border-transparent  hover:bg-[--color-background-button-default-hover] hover:border-[--color-background-button-default-hover]`,
        'default-on': `rounded enabled:focus-visible:outline-highlight text  cursor-pointer bg-[--color-background-button-default] hover:bg-[--color-background-button-default-hover] hover:border-[--color-background-button-default-hover]`,
        'success-off': `rounded cursor-pointer enabled:focus-visible:outline-success border-transparent hover:bg-success hover:border-success hover:text-[hsl(var(--white-50))] `,
        'success-on': `rounded cursor-pointer enabled:focus-visible:outline-success bg-success border-success hover:bg-success-hover hover:border-success-hover text-[hsl(var(--white-50))]`,
        'primary-off': `rounded cursor-pointer enabled:focus-visible:outline-highlight bg-[--color-background-button-default] hover:bg-highlight hover:border-highlight hover:text-[hsl(var(--white-50))]`,
        'primary-on': `rounded cursor-pointer enabled:focus-visible:outline-highlight hover:border-highlight-hover border-highlight text-[hsl(var(--white-50))] bg-highlight hover:bg-highlight-hover`,
        'warning-off': `rounded enabled:focus-visible:outline-warning cursor-pointer hover:bg-warning hover:border-warning hover:text-[hsl(var(--white-50))]`,
        'warning-on': `rounded enabled:focus-visible:outline-warning cursor-pointer hover:border-warning-hover text-[hsl(var(--white-50))] bg-warning hover:bg-warning-hover border-warning`,
        'pending-off': `rounded enabled:focus-visible:outline-pending cursor-pointer bg-[--color-background-button-default] hover:border-pending hover:bg-pending hover:text-[hsl(var(--gray-950))]`,
        'pending-on': `rounded enabled:focus-visible:outline-pending cursor-pointer border-pending hover:border-pending-hover text-[hsl(var(--gray-950))] bg-pending hover:bg-pending-hover`,
        'disabled': `rounded enabled:focus-visible:outline-highlight text-disabled bg-disabled cursor-not-allowed`,
        'icon-off': `rounded enabled:focus-visible:outline-highlight cursor-pointer text hover:bg-[--color-background-button-default-hover] border-transparent`,
        'icon-on': `rounded enabled:focus-visible:outline-highlight cursor-pointer text bg-[--color-background-button-default] hover:bg-[--color-background-button-default-hover]`,
      },
      size: {
        xs: 'h-[22px] px-[12px] text-xs',
        sm: 'h-[26px] px-[12px] text-sm inline-flex',
        md: 'h-[30px] px-[12px] inline-flex text-sm',
        lg: 'h-[38px] px-[20px] inline-flex',
        xl: 'h-[42px] px-[24px] inline-flex',
      },
    },
    defaultVariants: {
      type: 'default-off',
      size: 'md',
    },
  }
);

const isOnlyIcon = computed(() => {
  if (title) return false;
  if (slot?.title !== undefined) return false;
  if (icon) return true;
  return !!postIcon;
});

const tooltip = computed(() => {
  if (loading) return 'loading';
  if (disabled && disabledToolTipText) return disabledToolTipText;
  return toolTipText;
});

const isDisabled = computed(() => disabled || loading);

const btn = ref<HTMLButtonElement | null>();
const wrapper = ref<HTMLSpanElement | null>();

let toolInstance: ToolTipInstance | null = null;

onMounted(async () => {
  await nextTick();
  if (wrapper.value) {
    toolInstance = tool(wrapper.value, tooltip.value, undefined, 500);
  }
});

watch(
  tooltip,
  (v) => {
    if (!wrapper.value || !toolInstance) return;
    if (!v) {
      toolInstance.disable();
    } else {
      toolInstance.enable();
      toolInstance.setContent(v);
    }
  },
  { deep: true, immediate: true }
);

const onClick = (event: MouseEvent) => {
  if (stopClick) event.stopPropagation();

  emit('click', event);
};

defineOptions({
  inheritAttrs: false,
});

defineExpose({
  button: btn,
});

const isOfType = computed(() => {
  if (isDisabled.value) return 'disabled';
  if (isOnlyIcon.value && !onlyIconEmphasized) return `icon-${emphasized ? 'on' : 'off'}`;
  return `${type}-${emphasized ? 'on' : 'off'}`;
});
</script>

<template>
  <span
    ref="wrapper"
    class="rounded"
    :class="[isBlock ? 'block' : 'inline-flex h-fit w-fit items-stretch', wrapperClass]">
    <button
      ref="btn"
      :class="
        twMerge(
          !noStyle ? button({ type: isOfType, size: size }) : '',
          'relative',
          isBlock ? 'w-full' : '',
          isOnlyIcon ? 'aspect-1 px-0' : '',
          $attrs.class,
          hoverTitle !== null ? 'group/button' : ''
        )
      "
      :tabindex="tabindex"
      type="button"
      :disabled="isDisabled"
      v-bind="$attrs"
      @click="onClick">
      <span
        :class="loading && !icon ? 'invisible' : 'visible'"
        class="flex items-center justify-center gap-edge-1/4">
        <slot>
          <slot
            v-if="icon && !loading"
            name="pre">
            <span :class="{ 'block group-hover/button:hidden': hoverIcon }">
              <i
                :class="icon"
                class="fa fa-fw" />
            </span>
            <span class="hidden group-hover/button:block">
              <i
                v-if="hoverIcon"
                :class="hoverIcon"
                class="fa fa-fw" />
            </span>
          </slot>

          <span v-else-if="icon && loading">
            <i class="fa fa-fw fa-circle-o-notch fa-spin" />
          </span>

          <slot name="title">
            <template v-if="title">
              <span :class="{ 'block group-hover/button:hidden': hoverTitle }"> {{ title }} </span>
              <span
                v-if="hoverTitle"
                class="hidden group-hover/button:block">
                {{ hoverTitle }}
              </span>
            </template>
          </slot>

          <slot name="post">
            <span v-if="postIcon">
              <i
                :class="postIcon"
                class="fa fa-fw" />
            </span>
          </slot>
        </slot>
      </span>

      <span
        v-show="loading && !icon"
        class="absolute left-0 top-0 flex h-full w-full items-center justify-center">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" />
      </span>
      <span
        v-if="pingIndicator"
        class="absolute right-0 top-0 inline-flex h-[5px] w-[5px] animate-ping rounded-full bg-success"></span>
      <span
        v-if="dotIndicator"
        class="absolute right-0 top-0 inline-flex h-[5px] w-[5px] rounded-full bg-warning"></span>
    </button>
  </span>
</template>
